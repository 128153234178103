import React from "react"

export default class CollapsablePanel extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      open: true,
    }
  }
  toggle = () => {
    this.setState({ open: !this.state.open })
  }
  render() {
    return (
      <div className="panel">
        <a className="panel-heading" onClick={this.toggle}>
          <span className="editContent">{this.props.title}</span>
        </a>
        <div className={"panel-body collapse " + (this.state.open ? "in" : "")}>
          {this.props.children}
        </div>
      </div>
    )
  }
}
