import React from 'react';

import Layout from '../components/layout';
import Nav from '../components/nav';
import Footer from '../components/footer';
import Slogan from '../components/slogan';
import Contact from '../components/contact';
import SEO from '../components/seo';

import '../scss/attorno.scss';
import CollapsablePanel from '../components/collapsable-panel';

const Faq = () => (
  <Layout>
    <SEO
      title="FAQ"
      description="Heeft u toch nog vragen? Bekijk gerust de veelgestelde vragen. En ontdek waarom ons pizzakraam op uw feest thuishoort."
    />

    <Nav />

    <header
      className="intro-block py-3 cover-bg"
      style={{
        backgroundImage: 'url(/design/img/header/afgewerkte-pizza.jpg)',
        backgroundPosition: 'bottom',
      }}
    >
      <div className="container">
        <Slogan heading="De veelgestelde vragen" />
      </div>
    </header>

    <section
      className="pattern-bg py-8"
      style={{ backgroundImage: 'url(/design/img/pattern-background.png)' }}
    >
      <div className="container">
        <h2 className="title text-center">FAQ</h2>

        <CollapsablePanel title="Hoeveel gasten moet ik minimaal hebben alvorens jullie een pizzafeest willen komen verzorgen?">
          <p>
            Reeds vanaf 20 personen bakken wij onze verschillende soorten
            pizza’s op uw pizzaparty. Ons pizzakraam vormt steeds een belangrijk
            onderdeel van de gezelligheid op uw pizzafeest, zeker ook in een
            kleiner gezelschap.
          </p>
        </CollapsablePanel>

        <CollapsablePanel title="Op welk soort feesten komen jullie met het mobiel pizzakraam?">
          <p>
            Wij plaatsen onze mobiele pizzahoutoven op allerlei feesten:
            verjaardagsfeesten, communiefeesten, babyborrel,
            op-pensioen-stelling, huwelijksfeesten, gewone vriendschapsfeesten,
            enz...
          </p>
          <p>
            Ook bedrijven vormen onze doelgroep. Denk hierbij aan de
            nieuwjaarsreceptie, promotie, afscheidsfeest, kortom elk
            bedrijfsfeest. Eigenlijk staan wij ter beschikking in het kader van
            welk feest dan ook. Elk feest, waar dan ook in België, kunnen wij in
            de kortste keren omtoveren tot een heuse pizzaparty!
          </p>
        </CollapsablePanel>
        <CollapsablePanel title="Hoelang blijven wij op uw pizzafeest?">
          <p>
            Ons pizzafeest, voorbereiding en opruimen inbegrepen, neemt zo’n 3u
            in beslag.
          </p>
          <p>
            Wij komen met onze mobiele pizzahoutoven zo’n 1u voor aanvang van uw
            feest toe. Dan bekijken wij tezamen met U waar wij het best ons
            pizzakraam en buffettafel plaatsen. Ons uitgangspunt hierbij is om
            beide zo dicht mogelijk bij de gasten op te stellen. De mobiele
            pizzaoven staat sowieso buiten maar er kan geopteerd worden om de
            buffettafel binnenshuis op te stellen. Dit gebeurt wel eens bij
            slechter weer. Slecht weer is in ieder geval totaal geen obstakel
            voor ons pizzafeest. Wij stellen onze mobiele pizzaoven namelijk
            steeds op onder een mooie tent, zodat echt een pizzakraam gevormd
            wordt. Onze medewerkers brengen steeds de pizza’s tot op de
            buffettafel.
          </p>
          <p>
            De pizza’s, die ’s morgens in onze installaties werden voorgebakken,
            worden op uw pizzafeest zelf, eerst belegd. Het bakken van 1 pizza,
            eigenlijk 2 tegelijkertijd per oven, in een pizzahoutoven op zo’n
            400 graden Celsius, duurt ongeveer 1 minuut. Dit gaat dus snel en
            maakt het mogelijk om heel vlug verschillende soorten pizza’s aan uw
            gasten voor te schotelen. Al uw gasten kunnen dus quasi
            tegelijkertijd van onze pizza’s genieten gezien de pizza’s
            onmiddellijk in punten, zo’n 6 à 8 stukken, gesneden worden.
          </p>
          <p>
            Het eten van de pizza’s neemt gemiddeld zo’n 1,5u in beslag. Na het
            pizzafestijn waar ieder, zoveel pizzastukken heeft gegeten als
            gewild, beginnen wij onmiddellijk met de opruim. Die duurt nog zo’n
            halfuurtje en achteraf blijkt uit niets meer dat er een pizzaparty
            heeft plaatsgevonden.
          </p>
        </CollapsablePanel>
        <CollapsablePanel title="Kan de mobiele pizzaoven overal geplaatst worden?">
          <p>
            De mobiele pizzaoven wordt geplaatst onder een tent met afmetingen
            3m × 3m. Dit is de ruimte die wij buitenshuis nodig hebben. De
            pizzaoven zelf is 70cm breed, 90cm lang en 170cm hoog en geraakt dus
            gemakkelijk door een standaard deur. Omdat luchtbandjes deel
            uitmaken van het transportsysteem van ons pizzaoven, kan die zonder
            enig probleem over moeilijk berijdbaar terrein (grind, zompige
            weide, besneeuwd, ...).
          </p>
        </CollapsablePanel>
        <CollapsablePanel title="Wat hebben jullie nodig?">
          <p>
            In principe hebben wij niets nodig, ... op een emmertje water na.
            Een aansluiting op het elektriciteitsnet is niet nodig. Het enige
            wat wel echt nodig is, is ieders goed humeur, iets waar het optreden
            van ons team, geloof ons, een vermeldenswaardige inbreng zal hebben.
          </p>
        </CollapsablePanel>
        <CollapsablePanel title="Gaat er van de mobiele pizza houtovens echt geen gevaar op brand of verbranden, uit?">
          <p>
            Brand of verbranden is echt totaal niet aan de orde. Het verbranden
            van de houtblokken gebeurt in de gesloten ruimte van de pizzaoven.
            Nooit ofte nimmer is er enig vlammetje dat uit de pizzaoven
            verschijnt. Geen enkel gevaar dus, ook niet voor de kleinste
            kindjes.
          </p>
        </CollapsablePanel>
        <CollapsablePanel title="Kunnen jullie pizza’s bakken die rekening houden met bepaalde voedselallergieën?">
          <p>
            Wij kunnen perfect pizza’s bakken waarbij rekening gehouden wordt
            met een bepaalde voedselallergie. Glutenvrije, lactosevrije of
            pizza’s zonder nog andere te vermijden voedingsstoffen, kunnen, op
            eenvoudige vermelding, zonder probleem gebakken worden in de
            pizzaoven en dit in de door U opgegeven hoeveelheid. Wij vragen U
            enkel om dit tijdig op te geven.
          </p>
        </CollapsablePanel>
      </div>
    </section>
    <Contact dark="true" />

    <Footer />
  </Layout>
);

export default Faq;
